import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

// Exported for testing
export interface RqWindow {
  readonly rqClear: (key?: string[]) => void;
}

declare global {
  interface Window {
    rqClear: (key?: string[]) => void;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

/*
  Primarily a test oriented feature. rqClear (react-query clear) will clear out the query cache.
  A query cache key can be specified to only clear out certain entries instead of the entire thing.
  This can be super useful for testing so we can leverage test fixtures to have better UI testing instead of having to do complex initial setup.

  This could also be used as an operational/debugging feature. Clearing out the query cache will essentially just do a client-side refresh of the server data
  for things that are in the cache. This could trigger a bunch of API requests depending on how many cache entries are active.
 */
export const ReactQueryUtility = () => {
  const [ready, setReady] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    window.rqClear = (key?: string[]) => {
      if (key) {
        queryClient
          .invalidateQueries({
            queryKey: key,
          })
          .catch(noOp);
      } else {
        queryClient.invalidateQueries().catch(noOp);
      }
    };
    setReady(true); // Used to help signal to the tests that the window function is ready.
  }, [queryClient]);

  return <div data-rqready={ready} />;
};
